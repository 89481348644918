import { useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {
  CardError,
  HeaderBlock,
  ExpandPanel,
  Toast,
  ViewContainer,
} from 'components'
import {
  Sheet,
  ProgressBar,
  Checkbox,
  Font,
  TextBlock,
  Button,
  ButtonBlock,
  Section,
} from '@politechdev/blocks-design-system'
import { dateFormat } from 'utils/constants'
import { currentDateEasternYMDDash } from 'utils/dateTime'
import {
  saveSchedules,
  fetchAutoAssignSystemJobStatus,
  fetchSchedulesOnDate,
} from 'requests/qualityControl'
import { useRequest } from 'hooks/useRequest'
import { USER_TYPES } from 'constants/qualityControl'
import { useInterval } from 'hooks'
import { useCurrent } from 'contexts/index'
import ScheduleItem from './ScheduleItem'
import NewScheduleItem from './NewScheduleItem'
import StaffScheduleMetrics from './StaffScheduleMetrics'
import styles from './Schedule.module.scss'
import {
  isUserScheduled,
  getSelectedDay,
  filterManagers,
  filterStaff,
  getDisplayableSchedules,
  addSchedule,
  updateSchedule,
  removeSchedule,
} from './utils'

const cx = classNames.bind(styles)

const days = [
  { label: 's', context: 'sunday' },
  { label: 'm', context: 'monday' },
  { label: 't', context: 'tuesday' },
  { label: 'w', context: 'wednesday' },
  { label: 't', context: 'thursday' },
  { label: 'f', context: 'friday' },
  { label: 's', context: 'saturday' },
]

const Schedule = ({ history }) => {
  const { t } = useTranslation()
  const { currentTurfPerformsExternalQC } = useCurrent()

  const [showNoPreviousSchedule, setShowNoPreviousSchedule] = useState(false)
  const [lastSelectedDay, setLastSelectedDay] = useState('')
  const [useAutoAssign, setUseAutoAssign] = useState(false)

  const [updatedSchedules, setUpdatedSchedules] = useState([])
  const [existingSchedules, setExistingSchedules] = useState([])
  const [newSchedules, setNewSchedules] = useState([])

  const { startInterval, resetInterval } = useInterval(null, 5000)

  const [assignmentInProgress, setAssignmentInProgress] = useState(false)
  const [assignmentComplete, setAssignmentComplete] = useState(false)

  const [autoAssignJobId, setAutoAssignJobId] = useState()

  const { makeRequest: systemJobStatusReq } = useRequest(
    fetchAutoAssignSystemJobStatus,
    {
      onSuccess: ({ status }) => {
        if (status === 'complete') {
          resetInterval()
          setAssignmentComplete(true)
          setAssignmentInProgress(false)
          setAutoAssignJobId(null)
          setTimeout(() => {
            history.push('/quality_control/manage/assign')
          }, 1000)
        } else {
          setAssignmentInProgress(true)
        }
      },
    }
  )

  useEffect(() => {
    if (autoAssignJobId) {
      startInterval(() => {
        systemJobStatusReq(autoAssignJobId)
      })
    }
  }, [autoAssignJobId])

  const {
    makeRequest: saveScheduleReq,
    isLoading: isSaveLoading,
    hasErrors: saveHasErrors,
  } = useRequest(saveSchedules, {
    onSuccess: jobId => {
      jobId
        ? setAutoAssignJobId(jobId)
        : history.push('/quality_control/manage/assign')
    },
  })

  const {
    makeRequest: fetchExistingSchedulesReq,
    isLoading: isFetchLoading,
    hasErrors: fetchHasErrors,
  } = useRequest(fetchSchedulesOnDate, {
    onSuccess: ({ schedules }) =>
      setExistingSchedules(
        schedules.filter(
          s =>
            !!s.turf.qc_config.performs_external_qc ===
            currentTurfPerformsExternalQC
        )
      ),
  })

  const schedulesByDate = useRequest(fetchSchedulesOnDate, {
    onSuccess: ({ schedules }) => {
      const schedulesForTurf = schedules
        .filter(
          s =>
            !!s.turf.qc_config.performs_external_qc ===
            currentTurfPerformsExternalQC
        )
        .map(({ id, ...schedule }) => schedule)

      if (!schedulesForTurf.length) {
        setShowNoPreviousSchedule(true)
        return
      }

      setNewSchedules(schedulesForTurf)
      setUpdatedSchedules([])
      setExistingSchedules([])
      setShowNoPreviousSchedule(false)
    },
  })

  useEffect(() => {
    fetchExistingSchedulesReq(currentDateEasternYMDDash, {
      fields: [
        'id',
        'user_type',
        'minutes',
        'date',
        { user: ['id', 'full_name'] },
        { turf: 'qc_config' },
      ],
    })
  }, [])

  const handleCancel = () => {
    history.push('/quality_control/manage')
  }

  const hasExistingSchedules = !!existingSchedules.length

  const displayableSchedules = getDisplayableSchedules({
    existingSchedules,
    newSchedules,
    updatedSchedules,
  })

  const managerSchedules = displayableSchedules.filter(filterManagers)
  const staffSchedules = displayableSchedules.filter(filterStaff)
  const scheduledUserIds = new Set(
    displayableSchedules.map(({ user }) => user.id)
  )

  const hasSomethingToSave = !!newSchedules.length || !!updatedSchedules.length
  const isSaveDisabled =
    !hasSomethingToSave ||
    newSchedules.some(({ minutes, user }) => !user.id || !minutes) ||
    updatedSchedules.some(({ minutes }) => !minutes)

  return (
    <>
      <HeaderBlock title={`${moment().format(dateFormat)} ${t('Schedule')}`}>
        <Toast
          text={t('Checking the auto assigner')}
          visible={autoAssignJobId && !assignmentInProgress}
        />
        <Toast
          text={t('The auto assigner is running')}
          visible={assignmentInProgress && !assignmentComplete}
        />
        <Toast
          text={t('Auto assignment complete')}
          visible={assignmentComplete}
        />
      </HeaderBlock>
      <ViewContainer>
        <Sheet className="quality-control__scheduler">
          <ProgressBar
            className="quality-control__scheduler__loadbar"
            show={isFetchLoading || isSaveLoading || autoAssignJobId}
            data-testid="loadbar"
          />
          <div className={cx('schedule__layout')}>
            <div>
              <form>
                <Section label={t('Shift Managers')}>
                  {managerSchedules.map(schedule => (
                    <ScheduleItem
                      key={`schedule-item-${schedule.localId}`}
                      schedule={schedule}
                      scheduledUserIds={scheduledUserIds}
                      onUpdate={updateSchedule({
                        setUpdatedSchedules,
                        setNewSchedules,
                      })}
                      removeSchedule={removeSchedule({
                        setUpdatedSchedules,
                        setNewSchedules,
                      })}
                      nameDisabled={isUserScheduled(
                        existingSchedules,
                        schedule
                      )}
                      userType={USER_TYPES.MANAGER}
                    />
                  ))}
                  <NewScheduleItem
                    onUpdate={addSchedule(setNewSchedules)}
                    userType={USER_TYPES.MANAGER}
                    lastSchedule={
                      managerSchedules.length
                        ? managerSchedules[managerSchedules.length - 1]
                        : undefined
                    }
                  />
                </Section>
                <Section label={t('Staff Members')}>
                  <StaffScheduleMetrics staffSchedules={staffSchedules} />
                  {staffSchedules.map(schedule => (
                    <ScheduleItem
                      key={`schedule-item-${schedule.localId}`}
                      schedule={schedule}
                      scheduledUserIds={scheduledUserIds}
                      onUpdate={updateSchedule({
                        setUpdatedSchedules,
                        setNewSchedules,
                      })}
                      removeSchedule={removeSchedule({
                        setUpdatedSchedules,
                        setNewSchedules,
                      })}
                      nameDisabled={isUserScheduled(
                        existingSchedules,
                        schedule
                      )}
                      userType={USER_TYPES.STAFF}
                    />
                  ))}
                  <NewScheduleItem
                    onUpdate={addSchedule(setNewSchedules)}
                    userType={USER_TYPES.STAFF}
                    lastSchedule={
                      staffSchedules.length
                        ? staffSchedules[staffSchedules.length - 1]
                        : undefined
                    }
                  />
                  <ButtonBlock>
                    <Button.Accent
                      disabled={isSaveDisabled || isSaveLoading}
                      onClick={() => {
                        saveScheduleReq(useAutoAssign, [
                          ...updatedSchedules,
                          ...newSchedules,
                        ])
                        window.scrollTo(0, 0)
                      }}
                    >
                      {t('Save')}
                    </Button.Accent>
                    <Button.Secondary onClick={handleCancel}>
                      {t('Cancel')}
                    </Button.Secondary>
                  </ButtonBlock>
                  <TextBlock>
                    <Checkbox
                      name="auto-assign-packets"
                      id="auto-assign-packets"
                      checked={useAutoAssign}
                      label={t('Auto assign packets')}
                      onChange={() => setUseAutoAssign(current => !current)}
                    />
                    <Font.Copy variant="hint">
                      {t(
                        `Auto assigning all packets may take several minutes and will happen in the background. Not all unassigned packets will be automatically assigned. You can make manual assignments during this time.`
                      )}
                    </Font.Copy>

                    <CardError hide={!fetchHasErrors && !saveHasErrors} />
                  </TextBlock>
                  <ExpandPanel label="*About the auto assigner">
                    <TextBlock>
                      <Font.Copy variant="hint">
                        {t(`The auto assigner will automatically determine which packets to assign
        to available staff on the schedule. It will prioritize packets that are
        closer to their deadline and assign them to staff members who have
        availability to take more packets into their workload. Workload
        availability is determined by the staff member’s scheduled hours and
        their historical workload completion average. In order to assign a
        packet to a given staff member, they also must have the correct QC
        permissions, language spoken, state training and turf that coincide with
        the packet requirements.`)}
                      </Font.Copy>
                    </TextBlock>
                  </ExpandPanel>
                </Section>
              </form>
            </div>
            <div>
              <TextBlock>
                <Font.Label>
                  {t('Start from a prior days schedule (optional)')}
                </Font.Label>
              </TextBlock>
              <div className={cx('day-select__container')}>
                {days.map(({ label, context }, dayIndex) => (
                  <Button
                    disabled={schedulesByDate.isLoading || hasExistingSchedules}
                    className={cx('day-select__button')}
                    onClick={() => {
                      setLastSelectedDay(capitalize(context))
                      schedulesByDate.makeRequest(getSelectedDay(dayIndex), {
                        fields: [
                          'id',
                          'user_type',
                          'minutes',
                          'date',
                          { user: ['id', 'full_name'] },
                          { turf: 'qc_config' },
                        ],
                      })
                    }}
                  >
                    {t(label, { context })}
                  </Button>
                ))}
              </div>
              {showNoPreviousSchedule && lastSelectedDay ? (
                <TextBlock>
                  <Font.Copy variant="hint">
                    {t(`No schedule found for last ${lastSelectedDay}`)}
                  </Font.Copy>
                </TextBlock>
              ) : null}
            </div>
          </div>
        </Sheet>
      </ViewContainer>
    </>
  )
}

export default Schedule
